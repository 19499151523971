import { makeStyles } from "@material-ui/core/styles";
import SsHeadline from "components/front/layout/SsHeadline";
import { ssCenterText, ssText } from "components/front/layout/SsStyles";
import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

const AboutAnimefundSection: FC<HTMLAttributes<HTMLElement>> = ({...props}) => {
  const classes = useStyles();
  return (
    <Root {...props}>
      <SsHeadline color="white" label="アニメファンドについて" />
      <div>
        <h1 className={classes.summary}>
          アニメファンドは「欲しい人」に「欲しい体験」を「欲しい価格」で提供するためのアニメ特化型クラウドファンディング・ふるさと納税のサービスです。
        </h1>
        <div className={classes.descriptionArea}>
          <p className={classes.descriptionTitle}>新しいアニメの“かたち”</p>
          <div className={classes.imageAreaPc}>
            <img src="/img/about_img_01.png" width="815" height="462" alt="" loading="lazy" />
          </div>
          <div className={classes.imageAreaSp}>
            <img src="/img/about_img_01--sp.png" width="355" height="290" alt="" loading="lazy" />
          </div>
          <h2 className={classes.description}>
            「もっとアニメを好きになりたい」「作品やプロダクションを応援したい」「アニメの聖地となる自治体を応援したい」と想うアニメファンはたくさんいます。しかしその想いを“かたち”にして還元できる仕組みは今までありませんでした。
            <br />
            そこで、アニメファンドは応援したい気持ちを伝えられる場所を作りました。
            <br />
            それが本サービスです。好きなアニメを直接応援する文化を創造します。
            <br />
            &nbsp;
            <br />
            アニメに対する応援したい気持ちを“かたち”にして、アニメを盛り上げていきましょう。
            <br />
            ファン一人一人の応援が日本のアニメをよりワクワクさせる作品に成長させます。
          </h2>
        </div>
        <div className={classes.descriptionArea}>
          <p className={classes.descriptionTitle}>創りたいアニメの“みらい”</p>
          <div className={classes.imageAreaPc}>
            <img src="/img/about_img_02.png" width="777" height="214" alt="" loading="lazy" />
          </div>
          <div className={classes.imageAreaSp}>
            <img src="/img/about_img_02--sp.png" width="335" height="426" alt="" loading="lazy" />
          </div>
          <h3 className={classes.description}>
            このサービスによってファンの応援したい気持ちをアニメビジネスの収益改善へ繋げます。
            <br />
            そして、ファンは応援をすることで「アニメへのクレジット」「名シーンの生原画」「関係者だけの試写会」などの特別なリターンが得られます。
            <br />
            また今後、アニメ制作会社・クリエーターをサポートする仕組みも構築していきます。
            <br />
            &nbsp;
            <br />
            ファンの皆様、あたらしいアニメの“みらい”を一緒に創っていきませんか？
          </h3>
        </div>
      </div>
    </Root>
  );
};
export default AboutAnimefundSection;

const Root = styled.section`
  color: #FFF;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  descriptionTitle: {
    ...ssCenterText,
    width: "auto",
    position: "relative",
    marginBottom: "30px",
    fontSize: "1.4em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      paddingBottom: "6px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      paddingBottom: "19px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "22px",
      paddingBottom: "19px",
    },
    "&:after": {
      content: "''",
      width: "118px",
      backgroundColor: "#fff",
      position: "absolute",
      bottom: "0",
      left: "50%",
      marginLeft: "-59px",
      [theme.breakpoints.down("sm")]: {
        height: "1px",
      },
      [theme.breakpoints.up("md")]: {
        height: "2px",
      },
    },
  },
  summary: {
    ...ssText,
    fontSize: "1.16em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "1.428em",
      marginBottom: "48px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: "1.55em",
      marginBottom: "60px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },
  descriptionArea: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "60px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "94px",
    },
  },
  imageAreaPc: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  imageAreaSp: {
    padding: "0 10px",
    "& img": {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  description: {
    ...ssCenterText,
    width: "678px",
    margin: "0 auto",
    textAlign: "justify",
    fontFeatureSettings: "'palt'",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "14px",
      lineHeight: "1.428em",
      marginTop: "30px",
    },
    [theme.breakpoints.up("md")]: {
      width: "678px",
      fontSize: "16px",
      lineHeight: "1.64em",
      marginTop: "44px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "800px",
      fontSize: "18px",
    },
  },
}));
